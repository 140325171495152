<template>
  <div>
    <h3 class="pb-2">
      Activity & Amenity (POI) Management
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.pointOfInterestForm"
    />
    <div v-if="!loading">
      <div v-if="!pointOfInterest">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">Activity & Amenity (POI)</div>
          </div>
          <div class="card-body">
            <div class="d-flex mb-2">
              <div class="align-self-center">Items Per Page:</div>
              <div class="ml-2">
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                  aria-label="Items Per Page"
                ></b-form-select>
              </div>
            </div>
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="tablePointsOfInterest"
              :current-page="currentPage"
              :per-page="perPage"
              stacked="md"
              small
              class="mt-4"
              bordered
              sort-icon-left
            >
              <template v-slot:cell(id)="data">
                <button
                  class="btn btn-primary btn-sm mr-2"
                  @click="view(data.item.id)"
                >
                  View
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  @click="modify(data.item.id)"
                >
                  Modify
                </button>
              </template>
            </b-table>
            <div class="d-flex">
              <div class="mr-auto">
                Showing
                {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }}
                to {{ perPage * currentPage - perPage + perPageDisplay }} of
                {{ totalRows }} entries
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-header">
          <div class="card-title mb-0">Amenity</div>
        </div>
        <div class="card-body">
          <ValidationObserver ref="pointOfInterestForm">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Amenity Name"
                    id="name"
                    rules="required"
                    v-model="pointOfInterest.name"
                    :disabled="viewOnly"
                  />
                  <small class="form-text text-muted mb-2"
                    >The name for the amenity.</small
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <ValidationProvider
                    rules="required"
                    name="Amenity Type"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <label
                      @click="$refs.select.focus()"
                      :class="{ error: errors[0] }"
                      for="amenityType"
                    >
                      Amenity Type
                      <span class="error">*</span>
                    </label>
                    <select
                      class="form-control form-control-lg"
                      id="amenityType"
                      v-model="pointOfInterest.pointOfInterestCategoryId"
                      v-bind="ariaInput"
                      :disabled="viewOnly"
                    >
                      <option
                        v-for="cat in pointOfInterestCategories"
                        :key="cat.id"
                        :value="cat.id"
                        aria-placeholder="Select Amenity Type..."
                        >{{ cat.name }}</option
                      >
                    </select>
                    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                        class="error"
                        v-bind="ariaMsg"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </ValidationProvider>
                  <small class="form-text text-muted mb-2"
                    >The amenity type associated to the amenity.</small
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Amenity Icon"
                    id="icon"
                    v-model="pointOfInterest.icon"
                    :disabled="viewOnly"
                  />
                  <small class="form-text text-muted mb-2"
                    >The icon for the amenity.</small
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <ValidationProvider
                    rules="required"
                    name="Searchable"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <label
                      @click="$refs.select.focus()"
                      :class="{ error: errors[0] }"
                      for="isSearchable"
                    >
                      Searchable
                      <span class="error">*</span>
                    </label>
                    <select
                      class="form-control form-control-lg"
                      id="isSearchable"
                      v-model="
                        pointOfInterest.tenantPointOfInterest.isSearchable
                      "
                      v-bind="ariaInput"
                      :disabled="viewOnly"
                    >
                      <option
                        v-for="(stat, index) in searchableStatues"
                        :key="index"
                        :value="stat.value"
                        aria-placeholder="Select Searchable..."
                        >{{ stat.name }}</option
                      >
                    </select>
                    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                        class="error"
                        v-bind="ariaMsg"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </ValidationProvider>
                  <small class="form-text text-muted mb-2">
                    Whether or not the amenity shows in filtering functions on
                    the website.
                  </small>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-lg btn-primary mt-4 mr-2"
                v-show="!viewOnly"
              >
                Save
              </button>
              <button
                class="btn btn-lg btn-primary mt-4 mr-2"
                @click="viewOnly = false"
                type="button"
                v-show="viewOnly"
              >
                Modify
              </button>
              <button
                class="btn btn-cancel mt-4 mr-2"
                @click="cancel"
                type="button"
              >
                Cancel
              </button>
              <p class="mt-2">
                <span style="color: red;">*</span> Indicates a required field
              </p>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
export default {
  name: "AdminPointsOfInterestManagement",
  title: "Admin - Activity & Amenity (POI) Management",
  components: {
    ValidationObserver,
    ValidationProvider,
    TextInput,
    FormErrorAlert
  },
  data() {
    return {
      loading: false,
      errors: [],
      pointsOfInterest: [],
      pointOfInterest: null,
      pointOfInterestCategories: [],
      searchableStatues: [
        { name: "Yes", value: true },
        { name: "No", value: false }
      ],
      fields: [
        { key: "name", sortable: true, label: "Amenity Name" },
        {
          key: "pointOfInterestCategory",
          sortable: true,
          label: "Amenity Type"
        },
        { key: "searchable", sortable: true, label: "Searchable" },
        { key: "id", label: "Actions/Options", class: "actionsColumn" }
      ],
      viewOnly: false,
      currentPage: 1,
      totalRows: 25,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      perPageDisplay: 10
    };
  },
  methods: {
    async initialize() {
      this.loading = true;
      const service = new AdminLookupService(this.tenantId);
      const pointOfInterestCategories = await service.getPointOfInterestCategories();
      const pointsOfInterest = await service.getPointsOfInterest({
        isSearchable: false
      });
      await Promise.allSettled([
        pointOfInterestCategories,
        pointsOfInterest
      ]).then(responses => {
        if (
          responses[0].value.statusCode === "Success" &&
          responses[1].value.statusCode === "Success"
        ) {
          this.pointOfInterestCategories = responses[0].value.data;
          this.pointsOfInterest = responses[1].value.data;
          this.totalRows = this.pointsOfInterest.length;
          this.loading = false;
        } else {
          this.loading = false;
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    async onSubmit() {
      this.$refs.pointOfInterestForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.pointOfInterestForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.pointOfInterestForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            const service = new AdminLookupService(this.tenantId);
            const response = await service.updatePointOfInterest(
              this.pointOfInterest
            );
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message:
                  "Amenity " +
                  this.pointOfInterest.name +
                  " successfully modified.",
                layer: "admin"
              });
              this.initialize();
              this.cancel();
            } else {
              this.loading = false;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    },
    view(id) {
      this.viewOnly = true;
      this.pointOfInterest = {
        ...this.pointsOfInterest.find(x => x.id === id)
      };
    },
    modify(id) {
      this.viewOnly = false;
      this.pointOfInterest = {
        ...this.pointsOfInterest.find(x => x.id === id)
      };
    },
    cancel() {
      this.errors = [];
      this.viewOnly = false;
      this.pointOfInterest = null;
    },
    getCategoryName(id) {
      return this.pointOfInterestCategories.find(x => x.id === id)?.name;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    tablePointsOfInterest() {
      const tablePointsOfInterest = [];
      this.pointsOfInterest?.forEach(x => {
        tablePointsOfInterest.push({
          id: x.id,
          name: x.name,
          pointOfInterestCategory: this.getCategoryName(
            x.pointOfInterestCategoryId
          ),
          searchable: x.tenantPointOfInterest.isSearchable ? "Yes" : "No"
        });
      });
      return tablePointsOfInterest;
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style></style>
